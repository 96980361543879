import "../style/index.scss";
import "./jquery-ui.min.js";

'use strict';
const menuTrigger = document.getElementById('menuTrigger');
const menu = document.getElementById('menu');
const topMenuItems = document.querySelectorAll('.menu__item');

$(document).ready(() => {
  SetCounters();
  setInterval(SetCounters, IntervalMiliSec);
});

setTimeout(function () {
  let footerOffsetTop = footerOffset(),
    $scrolltop = $(".scrolltop");
  $scrolltop.on("click", function () {
    scrollTo($(".top-line"), 300);
  });
  $(window).on("scroll", function () {
    if ($(window).scrollTop() >= footerOffsetTop) {
      $scrolltop.addClass("scrolltop-end");
    } else {
      $scrolltop.removeClass("scrolltop-end");
    }
  }).on("load resize", function () {
    footerOffsetTop = footerOffset();
  });
}, 3000);

$(".menu-trigger").on("click", function () {
  $(".menu-trigger, .menu").toggleClass("active");
  $("html").toggleClass("fixed");
});

$(".footer-nav__item.info .footer-nav__link").on("click", function (e) {
  e.preventDefault();
  let sectionId = $(this).attr("href");
  $(sectionId).toggleClass("visible");

  if ($(sectionId).hasClass('visible')) {
    scrollTo($(sectionId), 300);
  }
});

$(".form__input").on('focus change blur', function () {
  focused($(this), 'selected');
}).each(function () {
  focused($(this), 'selected');
});

$(document).mouseup(function (event) {
  outEvent(event, $("#location-popup"), function () {
    $(this).fadeOut(200);
    $('#location-city').val('').removeClass('selected').next().removeClass('selected');
  });
});

$('#location-city').autocomplete({
  source: '/api/v1/get_city_autocomplete',
  minLength: 2,
  select: function (event, ui) {
    $("#location-popup").fadeOut(200); //закрываем попап
    $(this).val('');

    $('.location__city').text(ui.item.value); //перезаписываем выбранный город в меню
    $('.popup__title').text(ui.item.label); //перезаписываем выбранный город в меню

    //отправляем на сервер запрос, чтобы запомнить город
    $.ajax({
      type: 'POST',
      url: '/api/v1/confirm_location',
      data: 'city_id=' + ui.item.id,
      complete: function () {
        $(location).attr("href", "/");
      },
    });
  },
  open: function open(event, ui) {
    let listWidth = $(window).innerWidth() < 660 ? $(this).innerWidth() : 400;
    $(".ui-menu").css({
      maxWidth: listWidth + 'px'
    });
    $('html').addClass('fixed');
  },
  close: function close(event, ui) {
    $('html').removeClass('fixed');
  }
});

let IntervalMiliSec = 10000;

function SetCounters() {
  let now = new Date();
  let start = Math.round(((new Date().getTime() / 1000) - 1509887587) / 10); //Раз в 10 секунд
  start = start % 1000000;
  $(".counter__value").text(start);
}

function outEvent(event, DOMElement, callback) {
  if ( // если событие произошло вне DOMElement
    !DOMElement.is(event.target) // и не в его дочерних элементах
    && DOMElement.has(event.target).length === 0) {
    // выполнить callback для DOMElement
    callback.apply(DOMElement, arguments);
  }
}

function scrollTo($el, duration) {
  if ($el) {
    try {
      $("html, body").stop().animate({
        scrollTop: $el.offset().top
      }, duration);
    } catch (e) {
      //console.error(e);
    }
  }
}

function footerOffset() {
  return Math.round($("footer").offset().top - $(window).innerHeight());
}

function focused($input, selectClass) {
  if ($input.val().trim().length) {
    $input.addClass(selectClass);
    $input.next().addClass(selectClass);
  } else {
    $input.removeClass(selectClass);
    $input.next().removeClass(selectClass);
  }
}

$(document).ready(function () {
  if (!$('.location__city').text()) {
    ymaps.ready(init);
    function init() {
      var geolocation = ymaps.geolocation;
      var city = geolocation.city;
      var region = geolocation.region;
      $('.popup__title').text(city + ', ' + region);
      $('.location__city').text(city);
      var utmContent = "";
      if ($('.utm__content').text()) {
        utmContent = "?utm_content=" + $('.utm__content').text();
      }
    }
  }
  $(".nav-link").on("click", function (e) {
    e.preventDefault();
    let $this = $(this),sectionId = $this.attr("href");
    try {
      $(".menu-trigger, .menu").removeClass("active");
      $("html").removeClass("fixed");
      scrollTo($(sectionId), 300);
      location.hash = sectionId;
    } catch (e) {
      console.error(e);
    }
  });
  $('#nav-menu').click(function () {
    $('.header .menu').addClass('nav-open');
    $('.header').addClass('active');
  });
  $('#closeMenu').click(function () {
    $('.header .menu').removeClass('nav-open')
    $('.header').removeClass('active');
  });
  $('.nav-link').click(function () {
    $('.header .menu').removeClass('nav-open')
    $('.header').removeClass('active');
  });
  $('.advises__wrapper').on('click', function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass('active')
    } else {
      $(this).addClass('active')
    }
  });
  $(window).scroll(function () {
    if ($(this).scrollTop() > 350) {
      $('.scrollup').fadeIn();
    }
    else {
      $('.scrollup').fadeOut();
    }
  });
  $('.scrollup').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });
});